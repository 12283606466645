exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aruku-jsx": () => import("./../../../src/pages/aruku.jsx" /* webpackChunkName: "component---src-pages-aruku-jsx" */),
  "component---src-pages-checkout-button-js": () => import("./../../../src/pages/checkout_button.js" /* webpackChunkName: "component---src-pages-checkout-button-js" */),
  "component---src-pages-dasu-jsx": () => import("./../../../src/pages/dasu.jsx" /* webpackChunkName: "component---src-pages-dasu-jsx" */),
  "component---src-pages-fusegu-jsx": () => import("./../../../src/pages/fusegu.jsx" /* webpackChunkName: "component---src-pages-fusegu-jsx" */),
  "component---src-pages-hanasu-jsx": () => import("./../../../src/pages/hanasu.jsx" /* webpackChunkName: "component---src-pages-hanasu-jsx" */),
  "component---src-pages-idaku-jsx": () => import("./../../../src/pages/idaku.jsx" /* webpackChunkName: "component---src-pages-idaku-jsx" */),
  "component---src-pages-iku-jsx": () => import("./../../../src/pages/iku.jsx" /* webpackChunkName: "component---src-pages-iku-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kanjiguide-jsx": () => import("./../../../src/pages/kanjiguide.jsx" /* webpackChunkName: "component---src-pages-kanjiguide-jsx" */),
  "component---src-pages-kanjiquiz-js": () => import("./../../../src/pages/kanjiquiz.js" /* webpackChunkName: "component---src-pages-kanjiquiz-js" */),
  "component---src-pages-kasegu-jsx": () => import("./../../../src/pages/kasegu.jsx" /* webpackChunkName: "component---src-pages-kasegu-jsx" */),
  "component---src-pages-katsu-jsx": () => import("./../../../src/pages/katsu.jsx" /* webpackChunkName: "component---src-pages-katsu-jsx" */),
  "component---src-pages-kau-jsx": () => import("./../../../src/pages/kau.jsx" /* webpackChunkName: "component---src-pages-kau-jsx" */),
  "component---src-pages-kesu-jsx": () => import("./../../../src/pages/kesu.jsx" /* webpackChunkName: "component---src-pages-kesu-jsx" */),
  "component---src-pages-kiku-jsx": () => import("./../../../src/pages/kiku.jsx" /* webpackChunkName: "component---src-pages-kiku-jsx" */),
  "component---src-pages-kiru切-jsx": () => import("./../../../src/pages/kiru切.jsx" /* webpackChunkName: "component---src-pages-kiru切-jsx" */),
  "component---src-pages-kiru着-jsx": () => import("./../../../src/pages/kiru着.jsx" /* webpackChunkName: "component---src-pages-kiru着-jsx" */),
  "component---src-pages-kuru-jsx": () => import("./../../../src/pages/kuru.jsx" /* webpackChunkName: "component---src-pages-kuru-jsx" */),
  "component---src-pages-matsu-jsx": () => import("./../../../src/pages/matsu.jsx" /* webpackChunkName: "component---src-pages-matsu-jsx" */),
  "component---src-pages-motsu-jsx": () => import("./../../../src/pages/motsu.jsx" /* webpackChunkName: "component---src-pages-motsu-jsx" */),
  "component---src-pages-naku-jsx": () => import("./../../../src/pages/naku.jsx" /* webpackChunkName: "component---src-pages-naku-jsx" */),
  "component---src-pages-naru-jsx": () => import("./../../../src/pages/naru.jsx" /* webpackChunkName: "component---src-pages-naru-jsx" */),
  "component---src-pages-nomu-jsx": () => import("./../../../src/pages/nomu.jsx" /* webpackChunkName: "component---src-pages-nomu-jsx" */),
  "component---src-pages-okoru-jsx": () => import("./../../../src/pages/okoru.jsx" /* webpackChunkName: "component---src-pages-okoru-jsx" */),
  "component---src-pages-onyomi-graphql-kanji-js": () => import("./../../../src/pages/onyomi/graphql_kanji.js" /* webpackChunkName: "component---src-pages-onyomi-graphql-kanji-js" */),
  "component---src-pages-onyomi-kanji-json-kanji-js": () => import("./../../../src/pages/onyomi/{KanjiJson.kanji}.js" /* webpackChunkName: "component---src-pages-onyomi-kanji-json-kanji-js" */),
  "component---src-pages-osu-jsx": () => import("./../../../src/pages/osu.jsx" /* webpackChunkName: "component---src-pages-osu-jsx" */),
  "component---src-pages-ou-jsx": () => import("./../../../src/pages/ou.jsx" /* webpackChunkName: "component---src-pages-ou-jsx" */),
  "component---src-pages-oyogu-jsx": () => import("./../../../src/pages/oyogu.jsx" /* webpackChunkName: "component---src-pages-oyogu-jsx" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-suru-jsx": () => import("./../../../src/pages/suru.jsx" /* webpackChunkName: "component---src-pages-suru-jsx" */),
  "component---src-pages-suu-jsx": () => import("./../../../src/pages/suu.jsx" /* webpackChunkName: "component---src-pages-suu-jsx" */),
  "component---src-pages-tatakau-jsx": () => import("./../../../src/pages/tatakau.jsx" /* webpackChunkName: "component---src-pages-tatakau-jsx" */),
  "component---src-pages-tatsu-jsx": () => import("./../../../src/pages/tatsu.jsx" /* webpackChunkName: "component---src-pages-tatsu-jsx" */),
  "component---src-pages-utau-jsx": () => import("./../../../src/pages/utau.jsx" /* webpackChunkName: "component---src-pages-utau-jsx" */),
  "component---src-pages-utsu-jsx": () => import("./../../../src/pages/utsu.jsx" /* webpackChunkName: "component---src-pages-utsu-jsx" */),
  "component---src-pages-yaru-jsx": () => import("./../../../src/pages/yaru.jsx" /* webpackChunkName: "component---src-pages-yaru-jsx" */),
  "component---src-pages-yobu-jsx": () => import("./../../../src/pages/yobu.jsx" /* webpackChunkName: "component---src-pages-yobu-jsx" */),
  "component---src-pages-yomu-jsx": () => import("./../../../src/pages/yomu.jsx" /* webpackChunkName: "component---src-pages-yomu-jsx" */)
}

